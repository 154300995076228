@media (max-width: 700px){
    .headshot {
        height: 100%;
    }
}

@media (max-width: 900px){
    .arrow {
        display: none;
    }
}